module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kavanagh Digital","short_name":"KavanaghDigital","start_url":"/","background_color":"#FFF","theme_color":"#192C3E","display":"minimal-ui","icon":"src/assets/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b116ab918d2049257f3021310c335219"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
